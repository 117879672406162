import React, { useState, useRef, useEffect } from "react";
import { Route, useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import Notification from "./Notification";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useDispatch, useSelector } from "react-redux";
import { showLoader, hideLoader, mainSlice, setLocation_id } from "../redux/mainSlice";
import { showMobileBar } from "../redux/sidebarSlice";
import VIV_Dropdown from "../shared/Form-Select";
import { OverlayPanel } from 'primereact/overlaypanel';
import { Divider } from 'primereact/divider';
import ConfirmationPopup from "../shared/ConfirmationPopup";
import httpClient from "../_util/api";
import FromSelect from "../shared/Form-Select";
const AppTopBar = () => {
  const [selectedStatus, setSelectedStatus] = useState("All");
  const toast_Ref = useRef(null);
  const menuLeft = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.mainSlice.userdetail);
  const confirmationPopupRef = useRef(null);
  const [locationId,setLocationId] = useState()
  const [locationDetails,setLocationDetails] = useState()
  const userImage = useSelector((state) => state.mainSlice.userImage);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const handleLogout = () => {
    
       confirmationPopupRef.current?.showPopUp();
    
  };
  const locations = [
    { id: 1, name: "Location 1" },
    { id: 2, name: "Location 2" },
    { id: 3, name: "Location 3" },
    { id: 4, name: "Location 4" },
    { id: 5, name: "Location 5" },
  ];

  const statuses = [
    { name: "All" },
    { name: "Receiving" },
    { name: "Inventory" },
    { name: "Orders" },
    { name: "Outbound" },
    { name: "Setup" },
    { name: "Reports" },
  ];

  const setLocation = async (value) => {
    console.log(value);
    setLocationId(value)
    
    dispatch(setLocation_id(value))
  }
  
  const fetchLocations = async () => {
    try {
      const { data } = await httpClient.get("/lookup-locations");
      console.log(data);
      if (data.status == 200) {
        setLocationDetails(data.data)
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(() => {
    fetchLocations()
  }, []);
  

  
  const accept = async () => {
    dispatch(showLoader());
    try {
      const resp = await httpClient.post("logout");
      if (resp.status === 200) {
        dispatch(hideLoader());
        dispatch(mainSlice.actions.clearUserDetail());
        dispatch(mainSlice.actions.clearUserRole());
        dispatch(mainSlice.actions.clearUserMenus());
        localStorage.removeItem('user-token');
        navigate("/login");
      }
    } catch (error) {
      toast_Ref.current?.showMessage(
        "error",
        error?.response?.data?.message || "An error occurred",
        "",
        "i-notify"
      );
      dispatch(hideLoader());
    }
    
  };

  const reject = () => {
    //toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
  };

  const menuItems = [
    {
      label: "Profile",
      icon: "i-user",
      command: () => {
        navigate("/profile");
      },
    },
    {
      label: "Logout",
      icon: "i-signout",
      command: () => {
        confirmationPopupRef.current?.showPopUp();
      },
    },
  ];


  return (
    <div className="viv_header align-content-center flex justify-content-between">
      <h1 className=" topbar-title ">TP Resort</h1>
      <ConfirmationPopup
        ref={confirmationPopupRef}
        closable={true}
        title="Logout"
        message="Are you sure you want to logout?"
        onConfirm={() => {
          accept(); // Handle form submission on "Yes"
          confirmationPopupRef.current?.hidePopUp(); // Hide popup
        }}
        onCancel={() => confirmationPopupRef.current?.hidePopUp()}
        onHide={() => confirmationPopupRef.current?.hidePopUp() }// Hide popup on "No"
      />
      <Button className="mob-menuBtn hidden-xs hidden-lg hidden-md" icon={'pi i-menu'} onClick={() => {dispatch(showMobileBar())}} />
      {/* <div className="search">
        <Dropdown
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.value)}
          options={statuses}
          optionLabel="name"
          placeholder="All"
        />
        <span className="p-input-icon-right w-19rem">
          <i className="i-search" />
          <InputText placeholder="Search" className="header_searchBar" />
        </span>
      </div> */}
      <div className="user_info align-content-center align-items-center flex gap-2 justify-content-between">
        <Button className="mob-menuBtn mobiledevice hidden-sm hidden-lg hidden-md" icon={'pi i-menu'} onClick={() => { dispatch(showMobileBar()) }} />
        <FromSelect
          filter={true}
          title={``}
          widthClass={"w-12rem"}
          styleclass={"w-full"}
              optionValue='id'
              placeholder="Select Location"
              onSelect={(value) => setLocation(value)}
              selectedOption={locationId}
              options={locationDetails}
            />
        {/* <Dropdown value={selectedLocation} onChange={(e) => setSelectedLocation(e.value)} options={locations} optionLabel="name" placeholder="Select a Location" 
                filter  className="dropdown-container mr-2 p-inputtext-sm" /> */}
        <Notification />
        <img
          style={{ borderRadius: "50%" }}
          width={50}
          height={50}
          src={userInfo?.image_path ? userInfo?.image_path : "/images/profile-pic.png"}
          onClick={(event) => menuLeft.current.toggle(event)}
          className="cursor-pointer"
        />
        <div className="user_name flex flex-column">
          {
            userInfo?.roles?.includes('Admin') && <p id="user_role">Admin</p>
          }
          <p id="user">{userInfo?.username}</p>
        </div>
        <div className="align-items-center flex">
          {/* Custom Profile Menu */}
          <OverlayPanel ref={menuLeft} className="popup_userInfo">
        <div className="profile-menu">
          
          <div className="profile-section">
            <img 
            src={userInfo?.image_path ? userInfo?.image_path : '/images/profile-pic.png'}
            alt="Profile" 
            className="profile-image" 
            />
            <div className="profile-details">
              <div className="profile-name">{userInfo?.first_name} {userInfo?.last_name}</div>
              <div className="profile-email">{userInfo?.email}</div>
            </div>
          </div>
<Divider/>
          
          <div className="menu-items">
            <div className="menu-item" onClick={handleLogout} >
              <i className="pi pi-sign-out" style={{ marginRight: '8px' }}></i>
              Log out
            </div>
          </div>
        </div>
      </OverlayPanel>
        </div>
      </div>
    </div>
  );
};

export default AppTopBar;

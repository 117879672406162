import React, { useState, useEffect, useCallback } from "react";
import { PanelMenu } from "primereact/panelmenu";
import { useLocation } from "react-router-dom";
import MenuLink from "../router/menuLink";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export const AppMenu = () => {
  const location = useLocation();
  const userMenus = useSelector((state) => state.mainSlice.userMenus);

  const [menu, setMenu] = useState([]);

  const renderMenus = useCallback(() => {
    let newMenu = [];

    userMenus.forEach(m => {
      if (m?.submenu && m?.submenu.length) {
        let actMenu = [{
          label: m.title,
          icon: "ic-" + m.icon ,
          className: location.pathname.includes(m.title) ? "activepanel" : "",
          expanded: location.pathname.includes(m.title), // Ensures the menu stays open based on location
          items: []
        }];
        
        m.submenu.forEach((f, ind) => {
          actMenu[0].items.push({
            template: (
              <MenuLink
                key={ind}
                to={m.title + f.url}
                classes="p-menuitem-link"
                text={f.title}
                onClick={(e) => e.stopPropagation()} // Prevent event from closing the main menu
              />
            ),
          });
        });

        newMenu.push(actMenu[0]);
      } else {
        newMenu.push({
          label: m.title,
          className: location.pathname.includes(m.title) ? "activepanel" : "",
          icon: "ic-franchise",
          template: (
            <MenuLink
              to={m.url}
              classes="p-panelmenu-header-link"
              icon={"ic-" + m.icon}
              text={m.title}
              onClick={(e) => e.stopPropagation()} // Prevent event from closing the main menu
            />
          ),
        });
      }
    });

    setMenu(newMenu);  // Update state with the new menu structure
  }, [userMenus, location.pathname]);

  useEffect(() => {
    renderMenus();
  }, [renderMenus]);

  return (
    <>
      <PanelMenu model={menu} className="w-full viv_sideMenus" />
      <Link to="/profile" className="viv_sidemenu_profile_tab">
        <div className="">
          <p>
            <i className="pi pi-user mr-3"></i>
            <span className="text-lg" style={{ color: "#333333" }}>
              Profile
            </span>
          </p>
        </div>
      </Link>
    </>
  );
};
